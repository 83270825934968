import * as React from 'react';
import { Card, CardGroup, Button, NavDropdown } from 'react-bootstrap';
import _ from 'lodash';
import classnames from "classnames";
import moment from "moment";
import { unwrapResult } from '@reduxjs/toolkit'

import '../housekeeping.scss'
import * as actions from '../reducer'
import QRReaderForm from "../../../components/QRReaderForm";
import ResidentNoteForm from "../ResidentNoteForm";
import RoomHistoryForm from "../RoomHistoryForm";
import Swal, {runWithProgress, SwalForm } from '../../../components/Alert';
import { HKReportStatus, Resident, NoteType, Room, HKReport, AssignmentTemplate } from '../../../../store/models/domain'
import ColorUtils from '../../../../utils/ColorUtils';
import { authProvider } from "../../../../common/auth/AuthProvider";
import { useAppDispatch } from "../../../../store/store"
import { useStore } from "react-redux"
import { loadHousekeepingReports } from "../../../../store/reducers/domain/housekeeping"
import AreaFilterRenderItem, {AssignmentFilterRenderItem, AssignmentsFilterOption} from "./AreaFilterRenderItem"

interface Props {
    state: actions.CompState
    dispatch: actions.HkDispatch
    t: any
}

interface RoomBoxComp {
    roomId: string
    onClick(roomId: string): void
}

const HousekeepingDashboard = (props: Props) => {
    const {
        t, //Translation
        dispatch,
        state
    }: Props = props

    const store = useStore()
    const appDispatch = useAppDispatch()
    const isAdmin = authProvider.hasRole(authProvider.Roles.Nursing);

    const _changeAreaFilter = (level: number, selected: string) => {
        dispatch(actions.applyAreaFilter(level, selected))
    }

    const _changeRoomTypeFilter = (selected: string) => {
        dispatch(actions.applyRoomTypeFilter(selected))
    }

    const _changeAssignmentsFilter = (selected: actions.AssignmentsFilterOption, selectedSchedule?: AssignmentTemplate) => {
        dispatch(actions.applyAssignmentsFilter(selected, selectedSchedule))
    }

    const _selectRoom = (roomId: string, qrCode?: string) => {        

        let room = state.rooms.byId[roomId ?? ""];
        let roomTypeIds = state.roomTypes.typeMap[room.roomType];
        let roomType = state.roomTypes.byId[roomTypeIds?.find(r => true) ?? ""];

        if(!roomType || (roomType.routines ?? []).length === 0) {
            
            Swal.fire({
                icon: 'error',
                title: t('Could not start cleaning'),
                text: t('Could not start cleaning on this room as cleaning operations are not configured for \'{{roomType}}\' room type.', {roomType: room.roomType}),
                showConfirmButton: true
            })

            return;
        }

        let residentId = state.residentRoomLookup[roomId] ?? {};
        let resident = state.residents.byId[residentId];
       
        if(!!resident){
            _showPreCleanNote(resident)
                .then(() => _selectRoomCore(roomId, qrCode));
        } else {
            _selectRoomCore(roomId, qrCode);
        }        
    }

    const _selectRoomCore = (roomId: string, qrCode?: string) => {        

        const room = state.rooms.byId[roomId];
        //const roomSchedule = state.roomSchedules.byRoomId[room.id];      
        const lastReport = _.last(_.orderBy(room.housekeepingHistory ?? [], (h) => h.dateComplete));
       
        if(!!lastReport && lastReport.status === HKReportStatus.Incomplete /*&& 
            // Don't allow continue cleaning if it was started in different or finished time bucket
            (!roomSchedule?.scheduleBucket || 
             (roomSchedule.scheduleType === HKScheduleType.Cron || roomSchedule!.scheduleBucket!.end > moment()) && 
             roomSchedule!.scheduleBucket!.start < moment(lastReport.startDate))*/) {
            Swal.fire({
                title: t('Continue last cleaning report'),
                text: t("Do you want to continue last incomplete cleaning report for the room?"),                
                icon: 'warning',
                showCancelButton: true,                
                confirmButtonText: t('Yes'),
                cancelButtonText: t('No')
            })
            .then((result) => {                
                dispatch(actions.selectRoom(roomId, qrCode, !!result.value));
            });
        } else {
            dispatch(actions.selectRoom(roomId, qrCode, false));
        }        
    }

    const _startAuditMarking = (roomId: string, qrCode?: string) => {        

        let room = state.rooms.byId[roomId ?? ""];
        let roomTypeIds = state.roomTypes.typeMap[room.roomType];
        let roomType = state.roomTypes.byId[roomTypeIds?.find(r => true) ?? ""];

        if(!roomType || (roomType.routines ?? []).length === 0) {
            
            Swal.fire({
                icon: 'error',
                title: t('Could not start Pre-Audit Marking'),
                text: t('Could not start  Pre-Audit Marking on this room as cleaning operations are not configured for \'{{roomType}}\' room type.', {roomType: room.roomType}),
                showConfirmButton: true
            })

            return;
        }
       
        let lastMarkingReport = _.last(state.markingReports.filter(r => r.room.id === roomId));       
        if(!!lastMarkingReport && !room.housekeepingHistory?.some(h => moment(h.dateComplete) > moment(lastMarkingReport?.startDate)) ) {
            Swal.fire({
                title: t('Continue last Pre-Audit Marking'),
                text: t("Do you want to continue the last Pre-Audit Marking report for the room?"),                
                icon: 'warning',
                showCancelButton: true,                
                confirmButtonText: t('Yes'),
                cancelButtonText: t('No')
            })
            .then((result) => {                
                dispatch(actions.selectRoom(roomId, qrCode, !!result.value, true));
            });
        } else {
            dispatch(actions.selectRoom(roomId, qrCode, false, true));
        }        
    }


    const _showPreCleanNote = (resident: Resident) => {
        
        return SwalForm.fire({
            title: t('Pre-Clean Summary'),
            html: (<ResidentNoteForm
                values={{ 
                    ...resident, 
                    preCleanNote: (resident.notes ?? []).filter(n => n.type === NoteType.PreClean).map(n => n.notification).join("\n") }
                }
                submitLabel={t("Continue")}
                cancelLabel={t("Cancel")}
                showCancel={false}
                forPreClean={true}
                onSubmit={(results: any) => {
                    Swal.close();
                }}
                onCancel={(values: any) => { Swal.close() }} />
            )
        });
    }

    const _onRoomClick = (roomId: string) => {
        
        if(authProvider.hasRole(authProvider.Roles.Nursing)){
            _showRoomHistory(state.rooms.byId[roomId]);
        } else {
            _selectRoom(roomId);
        }
    }

    const _showRoomHistory = (room: Room) => {
        return SwalForm.fire({
            title: t('Room {{room}} Cleaning History', {room: room.roomNumber}),
            html: (<RoomHistoryForm
                room={room}
                roomTypes={Object.values(state.roomTypes.byId)}
                onNewReport={(room: Room) => {
                    Swal.close();
                    _selectRoom(room.id);
                }}
                onReportOpen={_openHistoryReport}
                onAuditReport={_auditReport}
                onAuditMarking={(room: Room) => {
                    Swal.close();
                    _startAuditMarking(room.id);
                }}
                onClose={() => { Swal.close() }}
                store={store}
            />)
        });
    }

    const _openHistoryReport = (reportId: string) => {

        runWithProgress(t("Opening report..."), () => {
            appDispatch(loadHousekeepingReports({ReportId: reportId}))
            .then(unwrapResult)
            .then((r: any) => {               
                const report = r[reportId] as HKReport;
                Swal.close();
                
                if(!report) {
                    throw new Error(`Couldn't find report ${reportId}`)
                }
                
                dispatch(actions.viewHistoryReport(report))
            }).catch(_handleError)
        });
    } 

    const _auditReport = (reportId: string) => {

        runWithProgress(t("Opening report for audit..."), () => {
            appDispatch(loadHousekeepingReports({ReportId: reportId}))
            .then(unwrapResult)
            .then((r: any) => {               
                const report = r[reportId] as HKReport;
                Swal.close();
                
                if(!report) {
                    throw new Error(`Couldn't find report ${reportId}`)
                }
                
                dispatch(actions.auditReport(report))
            }).catch(_handleError)
        });
    } 
    
    const _handleError = (error: any) => {
        Swal.fire({
            icon: 'error',
            title: t('Error: ' + (error.response?.data?.errors || error.message)),
            showConfirmButton: true
        })
    }

    const _scanQR = () => {
        SwalForm.fire({
            title: t('Scan QR Code'),
            html: (<QRReaderForm
                values={{ qrCode: '' }}
                submitLabel={t("OK")}
                cancelLabel={t("Cancel")}
                onSubmit={(results: any) => {
                    const qrCode = results.qrCode
                    for (const roomId of state.rooms.allIds) {
                        const room = state.rooms.byId[roomId]
                        if (room && room.qrCode === qrCode) {
                            _selectRoom(roomId, qrCode)
                            break
                        }
                    }
                    Swal.close()
                }}
                onCancel={(values: any) => { Swal.close() }} />
            )
        });
    }

    const RoomBoxRenderItem: React.FunctionComponent<RoomBoxComp> = ({ roomId, onClick }) => {
        let room = state.rooms.byId[roomId] ?? {}
        let residentId = state.residentRoomLookup[roomId] ?? {}
        let resident = state.residents.byId[residentId] ?? {}
        let filtered = !(state.roomTypeFilter.selected === 'All Room Types' || state.roomTypeFilter.selected === room.roomType)
        if (!filtered) {
            for (let i = 0; i < state.areaFilters.length; i++) {
                let filter = state.areaFilters[i]
                if (filter.selected === 'Everything') break;
                let area = room.facilityArea[filter.level]
                if (!area) break;
                filtered = area !== filter.selected
                if (filtered) break;
            }
        }
        if (filtered) return null

        const roomTypeIds = state.roomTypes.typeMap[room.roomType];
        const roomType = state.roomTypes.byId[roomTypeIds?.find(r => true) ?? ""];
        const roomState = !!room.roomState ? roomType?.roomStates?.find(s => s.stateName === room.roomState) : undefined;

        
        const roomSchedule = state.roomSchedules.byRoomId[room.id];
        const scheduledClean = roomSchedule?.cleanType;
        
        // Check if there is incomplete report
        const now = moment();
        const today = moment(now).startOf("day");
        const lastHistory = _.last(_.orderBy((room.housekeepingHistory ?? []), (h) => h.dateComplete));
        let incompleteReport = state.hkReports.find(r => r.id === lastHistory?.reportId);
        
        let isIncomplete = incompleteReport?.status === HKReportStatus.Incomplete /*&& 
            // Check incompleteReport belongs to current bucket            
            (!roomSchedule?.scheduleBucket || 
            // Don't check that bucket is completer for Cron
            (roomSchedule.scheduleType === HKScheduleType.Cron || roomSchedule!.scheduleBucket!.end > now) && roomSchedule!.scheduleBucket!.start < moment(incompleteReport.startDate))*/;
        // Complete if there are completed clean for today
        let todaysHistory = _.orderBy(room.housekeepingHistory ?? [], (h) => h.dateComplete).filter(r => moment(r.dateComplete) >= today);
        const lastCleaningHistory = _.last(todaysHistory);
        let isComplete = lastCleaningHistory?.status === HKReportStatus.Complete && (!scheduledClean || (lastCleaningHistory?.priority ?? 0) >= (scheduledClean.priority ?? 0)); 
        let lastMarkingReport = _.last(state.markingReports.filter(r => r.room.id === roomId));     

        // Just for debug 
        let debugInfo = "";
        if(isAdmin) {
            
            debugInfo = `Scheduled Clean: ${scheduledClean?.cleanType}\r\n` +
                        `Schedule Type: ${roomSchedule?.scheduleType}\r\n` +
                        `Time Bucket: ${roomSchedule?.scheduleBucket?.start.format("YYYY-MM-DD HH:mm")} - ${roomSchedule?.scheduleBucket?.end.format("YYYY-MM-DD HH:mm")}\r\n` +
                        `Due Soon: ${roomSchedule?.dueSoon}\r\n` + 
                        `Missed: ${roomSchedule?.missed}\r\n` + 
                        `Incomplete: ${roomSchedule?.incomplete}` + 
                        (!!roomSchedule?.prevScheduleBucket 
                            ? `\r\nPrev Time Bucket: ${roomSchedule?.prevScheduleBucket?.start.format("YYYY-MM-DD HH:mm")} - ${roomSchedule?.prevScheduleBucket?.end.format("YYYY-MM-DD HH:mm")}\r\n`
                            : '');
        }
        
        let style: any = {};
        // Define room color        
        let color: string | undefined = undefined; 
        if(isIncomplete) {            
            let roomType = state.roomTypes.byId[incompleteReport!.roomType.id];
            color = roomType?.routines?.find(c => incompleteReport?.roomType?.routines?.some(r => r.cleanType === c.cleanType))?.color;
        } else if(isComplete) {
            color = "#C5E379";
        } else if(!!scheduledClean) {
            color = scheduledClean.color;
        } else {
            // Take default color
            let roomTypeIds = state.roomTypes.typeMap[room.roomType]
            let roomType = state.roomTypes.byId[roomTypeIds?.find(r => true) ?? ""]
            color = roomType?.routines?.find(c => c.isDefault === true)?.color;
        }
        
        if(!!color) {
            style = {
                ...style,
                backgroundColor: color,
                color: ColorUtils.getContrastColor(color)
            }
        }

        let headerStyle: any = isComplete ? style : {}; 
        if(!!roomState) {
            color = roomState.color;
            headerStyle = {                
                backgroundColor: color,
                borderColor: roomState.borderColor, 
                borderStyle: roomState.style,
                borderWidth: "4px"
            };

            if(!!color) {
                headerStyle.color = ColorUtils.getContrastColor(color)
            }
        }

        let header = (<span className="smallText staffName">{(isComplete ? lastHistory?.staffMember : "")}</span>);
        let headerClass = "";
        if(!!roomState) {
            header = (<>{roomState.stateName}</>);
            headerClass = "roomBoxTitleState";
        } else if(roomSchedule?.dueSoon) {
            header = (<>{t('DUE SOON')}</>);
            headerClass = "roomBoxTitleDueSoon";
        } else if(isIncomplete) {
            header = (<>{t('INCOMPLETE')}</>);
            headerClass = "roomBoxTitleIncomplete";
        } else if(roomSchedule?.missed) {
            header = (<>{t('MISSED')}</>);
            headerClass = "roomBoxTitleMissed";
        }  else if(!!lastMarkingReport && moment(lastMarkingReport.startDate) > moment(lastHistory?.dateComplete) && authProvider.hasRole(authProvider.Roles.FacilityAssistant)) {
            header = (<>{t('Pre-Audit Marked')}</>);
            headerClass = "roomBoxTitleMarked";
        } 

        return (
            <Card key={"dr_" + room.id} className="roomBox" style={style} title={debugInfo} 
                ref={(node: any) => {
                    if (node && style.borderColor) {
                        node.style.setProperty("border-color", style.borderColor, "important");
                        node.style.setProperty("border-style", style.borderStyle, "important");
                        node.style.setProperty("border-width", style.borderWidth, "important");
                    }
                }}>
                <div className="roomBoxClickMask" onClick={e => onClick(roomId)}></div>
                <div className={classnames("roomBoxTitle", headerClass, {
                    roomBoxTitleInvisible: headerClass === "", 
                    roomBoxTitleIncomplete: isIncomplete && !roomState
                    })} style={headerStyle} >
                    {header}
                </div>
                <div className="roomBoxNumber"><h5 style={{...style, borderWidth: '0'}}>{room.roomNumber}</h5></div>

                <div className="roomBoxName smallText">
                    <p>{(resident?.namePrefix ?? '') + ' ' + (resident.firstName ?? '')}</p>
                    <p>{(resident.lastName ?? '') + (!!resident.nickName ? (' (' + resident.nickName + ')') : '')}</p>                    
                </div>

                {resident.fallRisk === true &&<div className="roomBoxIcon iconF"><p>F</p></div>}
                {resident.exitSeeking === true &&<div className="roomBoxIcon iconE"><p>E</p></div>}
                {resident.sexuallyAbusive === true &&<div className="roomBoxIcon iconS"><p>S</p></div>}
                {resident.verballyAbusive === true &&<div className="roomBoxIcon iconV"><p>V</p></div>}
                {resident.physicallyAggressive === true &&<div className="roomBoxIcon iconP"><p>P</p></div>}
                <p className="smallText">{room.roomType}</p>
            </Card>
        );
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-between" style={{position: "relative"}}>
                {!(!!state.staffMember.restrictedArea && 
                    ((state.staffMember.restrictedArea as AssignmentsFilterOption) !== AssignmentsFilterOption.OtherSchedules || 
                    state.staffMember.restrictedAreaAssignmentId === state.assignmentsFilter.selectedSchedule?.id)) && <div className="d-flex">                            
                    <div className="mr-1">                        
                        <AssignmentFilterRenderItem 
                            selected={state.assignmentsFilter.selected} options={state.assignmentsFilter.options} 
                            selectedSchedule={state.assignmentsFilter.selectedSchedule} otherSchedules={state.assignmentsFilter.otherSchedules}
                            onChange={(selected, schedule) => _changeAssignmentsFilter(selected, schedule)} />
                    </div>                    
                    <div className="mr-1">
                        <AreaFilterRenderItem key="filter_roomType" selected={state.roomTypeFilter.selected} defaultSelection="All Room Types"
                            options={state.roomTypeFilter.options} onChange={s => _changeRoomTypeFilter(s)} />
                    </div>
                    {state.areaFilters.map(filter => (
                    <AreaFilterRenderItem key={"filter_" + filter.level} selected={filter.selected} options={filter.options} onChange={s => _changeAreaFilter(filter.level, s)} />
                    ))}
                </div>}                
                <div className="d-flex">                    
                    <Button className="mx-2 p-0" variant="transparent" onClick={() => _scanQR()}><i className="h3 m-0 feather icon-camera" /></Button>
                </div>
            </div>
            {state.rooms.filteredIds.length > 0 ? <CardGroup className="d-flex flex-wrap">
                {state.rooms.filteredIds.map(roomId => (
                    <RoomBoxRenderItem key={"rb_" + roomId} roomId={roomId} onClick={_onRoomClick} />
                ))}
            </CardGroup>
            : <div className="noRoomsContainer d-flex align-items-center justify-content-center">
                <div>{t('All rooms are filtered out.')}</div>
            </div>}
        </>
    );
}

export default HousekeepingDashboard;
