import * as log from 'loglevel'
import remote from 'loglevel-plugin-remote';

import { authProvider } from "./auth"
import TracingContext from './tracingContext'

log.setLevel("info");

const customJSON = (logItem: any) => {
    logItem.level = logItem.level.label;
    return logItem;
};

const apiPath = `${process.env.REACT_APP_API_URI}/logger`;

remote.apply(log, {
    format: customJSON,
    url: apiPath,
    level: 'warn',
    capacity: 3,
    stacktrace: {
        depth: 30,
        levels: ['error']
    },
    headers: TracingContext.getTracingHeaders(),
    onUnauthorized: () => {
        authProvider.getAccessToken().then(token => remote.setToken(token));
    }
});

export const setLogToken = (token: string | null) => remote.setToken(token)

export default log;
