import moment from "moment";
import store, { loadCache, clearCache } from "../../store/store"
import { loadTenants, loadSystemUsers } from "../../store/reducers/domain/tenants"
import { getFacilities } from "../../store/reducers/domain/facilities"
import { loadHousekeepingConfig, loadHousekeepingReports, loadHousekeepingCleans, loadDailyStaffReports, loadAuditMarkingReports } from "../../store/reducers/domain/housekeeping"
import { getSchedules } from "../../store/actions/domain/staffSchedule"
import { getFacilitySchedules } from "../../store/actions/domain/facilitySchedule"
import { loadCHSSystem } from "../../store/reducers/domain/chsSystem";
import { loadDataSources } from "../../store/reducers/domain/sync";
import { authProvider } from "../auth/AuthProvider";


class AppCache
{
    constructor() {
        this.loadInitialStateFromCache()
    }

    clearCache(): Promise<any> {
        localStorage.removeItem('beetons-data') //Remove old cache of data
        return clearCache()
    }

    private checkError = (r: any): void => {
        if (!!r.error) {
            (window as any)._isDataLoading = false
            throw r.error;
        }
    }

    loadInitialStateFromAPI(): Promise<any> {
        (window as any)._isDataLoading = true;

        //let dispatch: AppDispatch = useAppDispatch.dispatch
        //Loads the state from the API into the redux cache
        let promise = new Promise((resolve) => {
            resolve(undefined)
        })
        promise = promise.then(() => store.dispatch(loadCHSSystem()))
        promise = promise.then((r: any) => {
            this.checkError(r);
            return store.dispatch(loadTenants());
        });
        promise = promise.then((r: any) => {
            this.checkError(r);
            return store.dispatch(loadSystemUsers());
        })
        promise = promise.then((r: any) => {
            this.checkError(r);
            return store.dispatch(getFacilities());
        })
        promise = promise.then((r: any) => {
            this.checkError(r);
            return store.dispatch(loadHousekeepingConfig());
        })
        promise = promise.then((r: any) => {
            this.checkError(r);
            return store.dispatch(getFacilitySchedules({}));
        })
        promise = promise.then((r: any) => {
            this.checkError(r);
            return store.dispatch(loadHousekeepingReports({ LatestRoomHistory: true, Status: 'Incomplete' }));
        })
        promise = promise.then((r: any) => {

            this.checkError(r);

            // Convert local time as utc due to it is converted to uts on deserialize
            const start = moment.utc(moment().add(-1, "months").startOf("month").format("YYYY-MM-DD HH:mm:ss")).toJSON();
            return store.dispatch(loadHousekeepingCleans({
                data: {
                    startDate: start
                }
            }));
        });
        promise = promise.then((r: any) => {
            this.checkError(r);
            return store.dispatch(loadAuditMarkingReports({ latestRoomHistory: true }));
        })
        promise = promise.then((r: any) => {
            this.checkError(r);
            
            // Convert local time as utc due to it is converted to uts on deserialize
            const start = moment.utc(moment().add(-1, "months").startOf("month").format("YYYY-MM-DD HH:mm:ss")).toJSON();
            const end = moment.utc(moment().add(1, "months").endOf("month").format("YYYY-MM-DD HH:mm:ss")).toJSON();
            
            return store.dispatch(getSchedules({
                dateRange: {
                    start: start,
                    end: end,
                },
                reload: true
            }));
        });
        promise = promise.then((r: any) => {
            this.checkError(r);
            return store.dispatch(loadDailyStaffReports({latestReport: true}));
        })
        
        if(authProvider.hasRole(authProvider.Roles.Nursing)) {
            promise = promise.then((r: any) => {
                this.checkError(r);
                return store.dispatch(loadDataSources());
            })
        }

        promise = promise.then((r: any) => {            
        
            this.checkError(r);
            (window as any)._isDataLoading = false
        });

        return promise
    }

    loadInitialStateFromCache(): Promise<any> {
        console.log("Loading initial state from cache")
        return loadCache()
    }
}

const appCache = new AppCache()
export default appCache
